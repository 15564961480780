export default {
  apiKey: "AIzaSyBu2qD1owIFR3NdFO844UtZe8ue2x0FAAg",
  authDomain: "ulsemo.firebaseapp.com",
  databaseURL: "https://ulsemo.firebaseio.com",
  projectId: "ulsemo",
  storageBucket: "ulsemo.appspot.com",
  messagingSenderId: "435102052685",
  appId: "1:435102052685:web:219dd680bb910e78028704",
  measurementId: "G-MYPSDKNNYT"
}
